
import { Parallax } from 'react-parallax';

const insideStyles = {
   padding: 20,
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%,-50%)"
};
function Services() {
   return (
      <div className="w-full bg-white text-center relative">
         <Parallax
            bgImage="untitled4.png"
            blur={{ min: -5, max: 5 }}
            bgImageStyle={{ backgroundPosition: "bottom bottom", objectFit: "cover" }}
            strength={200}>
            <div style={{ height: "50vh" }} className="relative">
               <div style={insideStyles} className=" font-bauhaus uppercase text-studioGrundge text-5xl bg-zinc-800 text-stone-400">Caution: Wear Safety Suit! We Are Under Construction</div>
            </div>
         </Parallax >
         <h1 className="w-11/12 text-4xl text-justify mx-auto my-20">
         </h1>
      </div >
   )
}
export default Services;