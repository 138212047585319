import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.css";
export default class Responsive extends Component {
   render() {
      var settings = {
         dots: true,
         arrows: false,
         autoplay: true,
         infinite: true,
         speed: 1000,
         slidesToShow: 3,
         slidesToScroll: 3,
         initialSlide: 0,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
               }
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
               }
            },
            {
               breakpoint: 480,
               settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }
         ],
      };
      return (
         <div className="mb-10 ">
            <div className="grid grid-cols-1 h-56 content-center" style={{ backgroundImage: `url('talkshow.jpg')`, backgroundPosition: '50% 70%', backgroundSize: 'cover', backgroundAttachment: 'clip' }}>
               <div className="">
                  <h1 className="block text-3xl lg:text-5xl bg-transparent font-bauhaus font-bold uppercase text-right text-studioWall px-5">Testimonials</h1>
               </div>
            </div>

            <Slider {...settings} className=" w-11/12 mx-auto">
               <div className="bg-zinc-200 px-3 my-5  ">
                  <img
                     src="avatar.webp"
                     className=" w-40 mt-5 block mx-auto"
                  />
                  <h3 className=" text-zinc-800 text-justify text-xl inline-block font-bold m-0 font-playfair">Mr. John Doe</h3>
                  <p className="text-justify mb-5 font-SourceSans italic text-zinc-500 ">
                     It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                  </p>
               </div>
               <div className="bg-zinc-200 px-3 my-5  " >
                  <img
                     src="avatar.webp"
                     className="w-40 mt-5 block mx-auto"
                  />
                  <h3 className=" text-zinc-800 text-justify text-xl inline-block font-bold m-0 font-playfair">Mr. Dober Sebastian</h3>
                  <p className="text-justify mb-5 font-SourceSans italic text-zinc-500">
                     It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                  </p>
               </div>
               <div className="bg-zinc-200 px-3 my-5  " >
                  <img
                     src="avatar.webp"
                     className=" w-40 mt-5 block mx-auto"
                  />
                  <h3 className=" text-zinc-800 text-justify text-xl inline-block font-bold m-0 font-playfair">Mrs. Chriss Wayne</h3>
                  <p className="text-justify mb-5 font-SourceSans italic text-zinc-500">
                     It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                  </p>
               </div>
               <div className="bg-zinc-200 px-3 my-5  " >
                  <img
                     src="avatar.webp"
                     className=" w-40 mt-5 block mx-auto"
                  />
                  <h3 className=" text-zinc-800 text-justify text-xl inline-block font-bold m-0 font-playfair">Miss Carlos Kappa</h3>
                  <p className="text-justify mb-5 font-SourceSans italic text-zinc-500 ">
                     It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                  </p>
               </div>
               <div className="bg-zinc-200 px-3 my-5  " >
                  <img
                     src="avatar.webp"
                     className=" w-40 mt-5 block mx-auto"
                  />
                  <h3 className=" text-zinc-800 text-justify text-xl inline-block font-bold m-0 font-playfair">Mr. Van dam</h3>
                  <p className="text-justify mb-5 font-SourceSans italic text-zinc-500 ">
                     It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                  </p>
               </div>
               <div className="bg-zinc-200 px-3 my-5" >
                  <img
                     src="avatar.webp"
                     className=" w-40 mt-5 block mx-auto"
                  />
                  <h3 className=" text-zinc-800 text-justify text-xl inline-block font-bold m-0 font-playfair">Mr. Carlos Pogba</h3>
                  <p className="text-justify mb-5 font-SourceSans italic text-zinc-500 ">
                     It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                  </p>
               </div>
            </Slider >
         </div >
      );
   }
}