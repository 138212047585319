
import './index.css';
import MainNav from './components/MainNav';
import LandingPage from './components/LandingPage';
import { Outlet } from 'react-router-dom';
function App() {
  return (
    <div className=" bg-gray-200">
      <MainNav />
      <Outlet />
    </div>
  );
}

export default App;
