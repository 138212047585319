import React, { Component } from "react";
import Slider from "react-slick";

export default class Partner extends Component {
   render() {
      const settings = {
         dots: false,
         arrows: false,
         infinite: true,
         slidesToShow: 6,
         slidesToScroll: 1,
         autoplay: true,
         speed: 5000,
         autoplaySpeed: 5000,
         cssEase: "linear"
      };
      return (
         <div className="w-100 bg-white py-10 mx-auto">
            <h1 className="text-center text-3xl font-bold font-playfair mb-10">we've worked with</h1>
            <Slider {...settings} className="justify-center items-center">
               <div className="">
                  <img src="download.png" className="block w-24 mx-auto img-fluid" />
               </div>
               <div>
                  <img src="download.png" className="block w-24 mx-auto img-fluid" />
               </div>
               <div>
                  <img src="download.png" className="block w-24 mx-auto img-fluid" />
               </div>
               <div>
                  <img src="download.png" className="block w-24 mx-auto img-fluid" />
               </div>
               <div>
                  <img src="download.png" className="block w-24 mx-auto img-fluid" />
               </div>
               <div>
                  <img src="download.png" className="block w-24 mx-auto img-fluid" />
               </div>
            </Slider>
         </div>
      );
   }
}