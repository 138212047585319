import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import 'tw-elements';
import App from './App';
import Services from './components/Services';
import LandingPage from './components/LandingPage';
import reportWebVitals from './reportWebVitals';
import Team from './components/Team';
import Album from './components/Album';
import IndividualProjects from './components/IndividualProjects';
import Portfolio from './components/Portfolio';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >
          <Route path="/" element={<LandingPage />} />
          <Route path="Home" element={<LandingPage />} />
          <Route path="Services" element={<Services />} />
          <Route path="Portfolio" element={<Portfolio />} />
          <Route path="Album" element={<Album />} />
          <Route path="ProjectDetail" element={<IndividualProjects />} />
          <Route path="Team" element={<Team />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
