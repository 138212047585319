import { Disclosure, Menu, Transition } from '@headlessui/react'
import { FaYoutubeSquare, FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import Partner from './Partner';

export default function Footer() {
   return (
      <>
         <Disclosure as="Footer" className="bg-zinc-200">
            <div className='relative'>
               <div className='absolute w-full h-full bg-gradient-to-t from-studioCream via-transparent to-transparent'></div>
               <div className='uppercase absolute text-4xl lg:text-6xl 
               font-bold font-bauhaus px-8 py-4
             text-studioWall top-2/3 text-center left-1/2 -translate-x-1/2 -translate-y-1/3'>
                  Get in touch
               </div>
               <div className='absolute w-full h-full bg-gradient-to-t from-zinc-300 via-transparent to-transparent -z-1'></div>
               <img
                  src='footer.jpg'
                  className='w-full img-fluid'
                  alt='backdrop'
               />
            </div>
            <div className='grid md:grid-cols-1 bg-studioCream pt-10'>
               <div>
                  <form className=' w-3/4 mx-auto bg-studioCream py-10 font-playfair'>
                     <div className="relative z-0 mb-6 w-full group">
                        <input type="email" name="floating_email" id="floating_email" className="block py-3 px-2 w-full  bg-studioWall border-none  appearance-none text-zinc-800 border-stone-600 dark:focus:border-zinc-800 focus:outline-none focus:ring-0 focus:border-stone-900 peer" placeholder=" " required />
                        <label for="floating_email" className="peer-focus:font-medium absolute text-stone-600  duration-300 transform -translate-y-8 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-zinc-800 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-x-3 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Email address</label>
                     </div>


                     <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 mb-6 w-full group">
                           <input type="text" name="floating_first_name" id="floating_first_name" className="block py-3 px-2 w-full  bg-studioWall border-none  appearance-none text-zinc-800 border-stone-600 dark:focus:border-zinc-800 focus:outline-none focus:ring-0 focus:border-stone-900 peer" placeholder=" " required />
                           <label for="floating_first_name" className="peer-focus:font-medium absolute text-stone-600  duration-300 transform -translate-y-8 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-zinc-800 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-x-3 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">First name</label>
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                           <input type="text" name="floating_last_name" id="floating_last_name" className="block py-3 px-2 w-full   bg-studioWall border-none  appearance-none text-zinc-800 border-stone-600 dark:focus:border-zinc-800 focus:outline-none focus:ring-0 focus:border-stone-900 peer" placeholder=" " required />
                           <label for="floating_last_name" className="peer-focus:font-medium absolute  text-stone-600  duration-300 transform -translate-y-8 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-zinc-800  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-x-3 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Last name</label>
                        </div>
                     </div>

                     <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 mb-6 w-full group">
                           <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="floating_phone" id="floating_phone" className="block py-3 px-2 w-full   bg-studioWall border-none  appearance-none text-zinc-800 border-stone-600 dark:focus:border-zinc-800 focus:outline-none focus:ring-0 focus:border-stone-900 peer" placeholder=" " required />
                           <label for="floating_phone" className="peer-focus:font-medium absolute  text-stone-600  duration-300 transform -translate-y-8 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-zinc-800  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-x-3 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Phone number</label>
                        </div>

                        <div className="relative z-0 mb-6 w-full group">
                           <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="floating_subject" id="floating_subject" className="block py-3 px-2 w-full   bg-studioWall border-none  appearance-none text-zinc-800 border-stone-600 dark:focus:border-zinc-800 focus:outline-none focus:ring-0 focus:border-stone-900 peer" placeholder=" " required />
                           <label for="floating_phone" className="peer-focus:font-medium absolute  text-stone-600  duration-300 transform -translate-y-8 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-zinc-800  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-x-3 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Subject</label>
                        </div>
                     </div>

                     <div className="relative z-0 mb-6 w-full group">
                        <textarea type="email" name="floating_msg" id="floating_msg" className="block py-3 px-2 w-full   bg-studioWall border-none  appearance-none text-zinc-800 border-stone-600 dark:focus:border-zinc-800 focus:outline-none focus:ring-0 focus:border-stone-900 peer" placeholder="Leave us a message" rows={10} required></textarea>
                     </div>
                     <button type="submit" className="text-studioCream bg-studioGrundge hover:bg-studioDark focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold  w-full sm:w-auto px-5 py-2.5 text-center ">Submit</button>
                  </form>
                  <div className='w-full mx-auto'>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.2325427453575!2d91.82400871487796!3d22.344846685300375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd9a26f6c8d3f%3A0x5e9bcde43e106d04!2sStudio%20Amateur!5e0!3m2!1sen!2sbd!4v1676811164041!5m2!1sen!2sbd" width="100%" height="400px" style={{ border: 0, margin: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
               </div>
            </div>
            <Partner />
            <div className='flex flex-wrap justify-between p-10 items-center bg-studioDark text-stone-500 '>
               <div className=''>
                  <a href='https://www.facebook.com/' className='text-studioGrundge hover:text-studioWall transition-all delay-300'><FaFacebookSquare className='text-3xl inline-block ' /> </a>
                  <a href='https://www.youtube.com/' className='text-studioGrundge hover:text-studioWall transition-all delay-300'><FaYoutubeSquare className='text-3xl inline-block ' /> </a>
                  <a href='https://www.instagram.com/' className='text-studioGrundge hover:text-studioWall transition-all delay-300'><FaInstagramSquare className='text-3xl inline-block ' /> </a>
               </div>
               <div>
                  <p className='font-SourceSans text-sm text-studioGrundge'>  © Studio Amateur All rights reserved </p>
               </div>
            </div>
         </Disclosure >
      </>
   )
}
