import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Parallax } from 'react-parallax'

export default function ServicesShort() {


   return (
      <>
         <Disclosure as="ServicesShort">
            <div className='relative grid grid-cols-1 lg:grid-cols-3 gap-5 p-10'>
               <div className='absolute w-full h-1/2 bg-studioDark top-20'></div>
               <div className='relative border-8 border-studioWall ' style={{ height: '100vh', backgroundImage: `url('creative-min.jpg')`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
                  <div className='w-full'>
                     <div className='absolute w-full h-full bg-gradient-to-b from-studioDark via-transparent to-transparent'></div>
                     <div className='absolute top-6 w-full h-auto'>
                        <h1 className='md:text-6xl text-3xl uppercase bg-studioWall text-studioGrundge font-bauhaus' style={{ lineSpacing: '1rem' }}> Interior Exterior</h1>
                        <ol className='font-playfair text-2xl font-bold text-studioCream p-2'>
                           <li>Apartment</li>
                           <li>Restaurant</li>
                           <li>Workstation</li>
                           <li>Convention Hall</li>
                           <li>Commercial Space</li>
                        </ol>
                     </div>
                  </div>
               </div>
               <div className='relative border-8 border-studioWall ' style={{ height: '100vh', backgroundImage: `url('architect.png')`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
                  <div className='w-full'>
                     <div className='absolute w-full h-full bg-gradient-to-b from-studioDark via-transparent to-transparent'></div>
                     <div className='absolute top-6 w-full h-auto'>
                        <h1 className='md:text-6xl text-3xl uppercase bg-studioWall text-studioGrundge font-bauhaus' style={{ lineSpacing: '1rem' }}> Architecture Structure</h1>
                        <ol className='font-playfair text-2xl font-bold text-studioCream p-2'>
                           <li>Pavilion</li>
                           <li>Monument</li>
                           <li>Mosque</li>
                        </ol>
                     </div>
                  </div>
               </div>
               <div className='relative border-8 border-studioWall ' style={{ height: '100vh', backgroundImage: `url('art-min.jpg')`, backgroundPosition: 'bottom', backgroundSize: 'cover' }}>
                  <div className='w-full'>
                     <div className='absolute w-full h-full bg-gradient-to-b from-studioDark via-transparent to-transparent'></div>
                     <div className='absolute top-6 w-full h-auto'>
                        <h1 className='md:text-6xl text-3xl uppercase bg-studioWall text-studioGrundge font-bauhaus' style={{ lineSpacing: '1rem' }}> Creative Arts</h1>
                        <ol className='font-playfair text-2xl font-bold text-studioCream p-2'>
                           <li>Painting</li>
                           <li>Sculpture</li>
                           <li>Teracotta</li>
                        </ol>
                     </div>
                  </div>
               </div>
            </div>

            <div className='grid lg:grid-cols-3 gap-3 py-10 items-start'>
               <div className='grid grid-cols-1 content-center relative text-justify py-10 p-10 overflow-hidden'>

                  <h1 className='absolute top-0 right-0 font-bauhaus text-9xl lg:text-9xl font-bold text-studioClay opacity-30'>01</h1>

                  <span className='text-3xl font-bold font-bauhaus uppercase text-studioGrundge'>Clients' needs</span>
                  <p className=' font-playfair text-lg my-4 text-studioDark'>
                     At the same time to get an idea about our client’s preferences, tests, needs, we try to
                     understand the client teste through counselling with the client. A designer must know about the client’s taste before creating a design.
                  </p>
               </div>

               <div className='grid grid-cols-1 content-center items-center relative text-justify p-10 overflow-hidden'>

                  <h1 className='absolute top-0 right-0 font-bauhaus  text-9xl lg:text-9xl font-bold text-studioClay opacity-30'>02</h1>

                  <span className='text-3xl font-bold font-bauhaus uppercase text-studioGrundge'>Research</span>
                  <p className=' font-playfair text-lg my-4 text-studioDark'>
                     We have to research space to make your house, office, restaurant, shopping mall, hotel or any space usable and vaesthetic. To do this research we follow a few steps, such as first we visit your space and collect its blueprint with measurement and then we do 2D planning in our studio considering the client’s best interest, and then we transform it into 3D for visualization.
                  </p>
               </div>


               <div className='grid grid-cols-1 content-center items-center  relative text-justify p-10 overflow-hidden'>

                  <h1 className='absolute top-0  right-0 font-bauhaus text-9xl lg:text-9xl font-bold text-studioClay opacity-30'>03</h1>

                  <span className='text-4xl font-bold font-bauhaus uppercase text-studioGrundge'>Design</span>
                  <p className=' font-playfair text-lg my-4 text-studioDark'>
                     While designing, we try to make the best use of your space and maintain the beauty and aesthetic as well as the uniqueness of the place. So that a home doesn’t seem like a restaurant or a shop doesn’t turn into a storeroom. Our research in design makes Studio Amateur incomparable to others. While designing we try to utilize maximum unusable space while maintaining sufficient walking space.
                  </p>
               </div>
            </div>
            <div className="grid grid-cols-1 relative">
               <div className='h-screen w-full  overflow-hidden' style={{ backgroundImage: `url('bg/clientThink.webp')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
                  <div className=' sm:mt-20 md:rotate-12 bg-gray-200 h-screen py-40' style={{ height: '100%', marginLeft: '-70%', marginTop: '20%' }}>
                     <div className='md:w-2/5 w-10/12 inline-block py-10 px-10 md:-rotate-12 md:left-1/2 md:-top-1/3 -top-0  left-1/2 md:translate-x-0 -translate-x-1/2 right-0 bg-studioCream' style={{
                        position: 'absolute',
                        boxShadow: '1px 1px 5px -5px',
                        borderRadius: '5px',
                     }}>
                        <p className='md:text-3xl text-xl font-playfair clear-both text-studioGrundge'>What We</p>
                        <p className='md:text-5xl text-3xl font-bold font-playfair clear-both text-studioDark'>Think About The Client</p>
                        <p className='md:text-base text-sm  font-playfair py-10 text-justify'>In this age of the internet, a design can be easily obtainable. Some of them can meet our needs. But when we try to implement it into our space, we face many kinds of problems. In Studio Amateur we try to be acquainted with client’s needs and tastes. We try to get a sense of the client’s mentality and unique taste.<br />
                           We try to take note of what kind of resource that specific space has. If it has enough light or air circulation. Is there sufficient walking space or is the privacy being maintained? Also, we try to inquire about how much control the client needs with temperature, smoke, odor and waste. Try to know how much maneuvering they need in their space. After knowing all that we try to design the perfect space for the client.<br />
                           We try to keep the client engaged in every step of our process. We first design a 2d plan with keeping the client’s needs in mind. After several times refining it and getting approval from the client we start the second step of the process which is 3d visualization and animation. Here the client can fully visualize his space, design, lighting, furniture & utility. Then we further refine our design by consulting with the client. When that design is approved, we give a quotation based on our design. After signing the contract, we start building the client’s perfect space.</p>
                     </div>
                  </div>
               </div>
            </div>

         </Disclosure >
      </>
   )
}
