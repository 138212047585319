import { Disclosure, Menu, Transition } from '@headlessui/react'
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'
import { TypeAnimation } from 'react-type-animation';
import "../index.css";

const navigation = [
   { name: 'Home', href: '#', current: true },
   { name: 'Projects', href: '#project', current: false },
   { name: 'Services', href: '#', current: false },
   { name: 'Team', href: '#', current: false },
   { name: 'Contact', href: '#', current: false },
]

function classNames(...classes) {
   return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
   return (
      <Disclosure as="Herobackdrop" className="bg-zinc-200 -z-50">
         <div className='relative h-screen overflow-hidden custom' style={{}}>
            <div className='absolute w-full top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 max-w-lg p-10 min-w-10'>
               {/* <h1 className=' font-bauhaus uppercase font-thin text-3xl text-center text-studioCream'>
                  Studio Amateur
               </h1> */}
               <p className='font-mono text-lg text-center text-gray-200'>
                  <TypeAnimation
                     // Same String at the start will only be typed once, initially
                     sequence={[
                        "We Are Listeners",
                        3000,
                        "We Are Thinkers",
                        3000,
                        'We Are Designers',
                        3000,
                        'We Are Creatives',
                        3000,
                        'We Are Studio Amateur',
                        3000,
                     ]}
                     speed={50} // Custom Speed from 1-99 - Default Speed: 40
                     wrapper="span" // Animation will be rendered as a <span>
                     repeat={Infinity} // Repeat this Animation Sequence infinitely
                     className="text-3xl"
                  />
               </p>
            </div>
            <div className='absolute w-full h-full top-0'></div>
            <div className='absolute w-full bottom-3'>
               <ArrowDownCircleIcon className='block w-8 text-white mx-auto animate-bounce bottom-0' />
            </div>
            <video autoPlay loop muted poster="bg/sub_bg.webp" className='w-full' style={{ objectFit: 'cover', height: 'inherit' }}>
               <source src="" type="video/webm" />
            </video>
         </div>
      </Disclosure >
   )
}
