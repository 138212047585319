
import { Parallax } from 'react-parallax';
import Projects from './Projects';
import Footer from './Footer';

const insideStyles = {
   padding: 20,
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%,-50%)"
};
function Portfolio() {
   return (
      <div>
         <Projects />
         <Footer />
      </div>
   )
}
export default Portfolio;