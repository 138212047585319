import { Fragment, useState, useCallback, useEffect } from 'react'
import { Parallax } from 'react-parallax';
import { useLocation } from 'react-router-dom';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Footer from '../components/Footer';



export default function Album() {
  const [index, setIndex] = useState(-1);
  const location = useLocation()
  const { from } = location.state
  const { client } = location.state
  const insideStyles = {
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  };
  // console.log(module)

  const [albumItems, initAlbum] = useState([])
  const getAlbum = async () => {
    const response = await import(`./imgFiles/${from}.ts`)
    return response;
  }
  useEffect(() => {
    getAlbum()
      .then((res) => {
        initAlbum(res.default)
      })
      .catch((e) => {
        console.log(e.message)
      })
  }, [])
  // console.log(albumItems);
  const slides = albumItems.map(({ src, width, height, images }) => ({
    src,
    width,
    height,
    srcSet: images.map((image) => ({
      src: image.src,
      width: image.width,
      height: image.height,
    })),
  }));


  if (slides.length > 0) {
    console.log(slides)
    return (
      <div className="w-full bg-gray-300 relative">
        <Parallax
          bgImage="bg/sub_bg1.webp"
          blur={{ min: -5, max: 5 }}
          bgImageStyle={{ objectFit: "cover", backgroundPosition: "center" }}
          style={{ backgroundPosition: "center", }}
          strength={200}>
          <div style={{ height: "50vh" }} className="relative">
            <div style={insideStyles} className=" font-playfair font-bold text-studioClay text-xl bg-zinc-800 text-stone-400"> Album: {client}</div>
          </div>
        </Parallax >
        <div className=' w-11/12 mx-auto py-20'>
          <p className='font-bold font-bauhaus text-left'></p>
          <PhotoAlbum
            photos={slides}
            layout="masonry"
            padding={2}
            spacing={5}
            targetRowWidth={150}
            onClick={({ index }) => setIndex(index)}
          />

          <Lightbox
            slides={slides}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            // enable optional lightbox plugins
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
          />
        </div>
        <Footer />
      </div >
    )
  }
}