import React from 'react';
import { Disclosure } from '@headlessui/react'
import { Parallax, Background } from 'react-parallax';

export default function About() {
   return (
      <Disclosure as="About" className="-z-50">
         <div className=''>
            <div className='grid  lg:grid-cols-1 items-center bg-gray-200'>
               {/* <div className='h-screen m-0' style={{ background: 'url("gdfg.jpg")', backgroundSize: 'no-repeat cover', backgroundPosition: 'bottom center' }}>

                  <div className='block lg:hidden'>
                     <h1 className='text-3xl text-left m-4 text-studioClay font-broadway font-bold my-5'> About <br />
                        <span className='text-studioDark text-5xl'>Studio Amateur</span></h1>
                     <p className=' text-justify font-playfair italic p-1 text-xl text-studioClay mb-10 bg-studioCream px-10 py-10 shadow-2xl'>
                        Studio Amateur has a number of experienced personnel in team who works both in house and the site. One cannot join any project without a minimum of 2 years of experience. Some of them has almost 8-10 year(s) working in similar projects. Our higher officials play an important rule to find out best into any project.
                        We have also a moderate collection of Carpenter, Painter, Lacquer/Deco/Pu Specialist, Electrician, Welder, Meson, Glass Fitter and many more.
                     </p>
                  </div>
               </div> */}

               <div className='relative'>

                  <div className=' w-2/3 float-right bg-studioDark py-40'>
                     <div className=' font-playfair text-studioCream'>
                        <p className=' w-full px-10 md:w-3/4 text-sm md:text-xl mx-auto text-justify'>
                           <h1 className='text-md md:text-5xl font-bold'>Live in Art</h1>
                           Your own house is a place where anyone can find his/her peace of mind. To make it more comfortable the house needs to be well decorated where he/she will find the desired design with natural air and lights.
                        </p>
                     </div>
                  </div>

                  <div className='w-s-full w-2/3 float-left -mt-20'>
                     <img src='bg/lightbg.jpg' alt='op' />
                  </div>
               </div>
               <div className='relative lg:block py-20'>
                  {/* <div className='absolute translate-y-1/2 w-20 h-20 rounded-full left-1/2 -top-2 bg-studioDark'></div> */}

                  <h1 className='uppercase text-xl md:text-3xl text-center text-studioGrundge font-bold font-bauhaus m-2'> about <br />
                     <span className='text-studioDark text-3xl md:text-5xl border-b-studioGrundge border-b-4 mb-3'>Studio Amateur</span></h1>
                  <p className='h-fit text-justify font-playfair font-bold text-md md:text-xl text-studioDark max-w-5xl mx-auto p-10'>
                     Studio Amateur has a number of experienced personnel in team who works both in house and the site. One cannot join any project without a minimum of 2 years of experience. Some of them has almost 8-10 year(s) working in similar projects. Our higher officials play an important rule to find out best into any project.
                     We have also a moderate collection of Carpenter, Painter, Lacquer/Deco/Pu Specialist, Electrician, Welder, Meson, Glass Fitter and many more.
                  </p>
               </div>
            </div>
         </div>
      </Disclosure >
   )
}
