import { Fragment, useState, useCallback } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import photos from './imgFiles/tkotta.ts';
import { Link } from 'react-router-dom';

import { ArrowDownLeftIcon, ArrowLeftIcon, ArrowRightIcon, } from '@heroicons/react/24/outline'
import { HiArrowNarrowRight } from "react-icons/hi";
import '../projects.css'

const slides = photos.map(({ src, width, height, images }) => ({
   src,
   width,
   height,
   srcSet: images.map((image) => ({
      src: image.src,
      width: image.width,
      height: image.height,
   })),
}));


export default function Projects() {
   const [index, setIndex] = useState(-1);
   const slides = photos.map(({ src, width, height, images }) => ({
      src,
      width,
      height,
      srcSet: images.map((image) => ({
         src: image.src,
         width: image.width,
         height: image.height,
      })),
   }));
   return (
      <>
         <Disclosure as="Projects">
            <div className='py-20'>
               <h1 className='text-5xl text-center font-bold font-playfair'>Portfolio</h1>
            </div>
            <div className='grids grid-cols-1 bg-gray-200' id="Portfolio">
               <div className='projectHead py-14 w-11/12 h-full' style={{ backgroundImage: "url('bg/apartments.webp')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                  <div class="absolute text-studioDark right-5 top-8  text-center text-5xl  font-bauhaus">Appartments</div>
               </div>

               {/* title */}


               <div className=' w-11/12 mx-auto pt-5 pb-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center'>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96  child' style={{ backgroundImage: "url('appartments/Engnr. Mr Oli Ahmed/EMr.Oli Ahmed_thumb.png')", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: Mr. Engnr. Oli Ahmed </p>
                        <Link to='/Album' state={{ from: "oliAhmed", client: "Engnr. Mr Oli Ahmed" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('appartments/Mr. AJIJ/thumb.jpg')", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: Mr. Ajij </p>
                        <Link to='/Album' state={{ from: "ajij", client: "Mr. Ajij" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>


                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('appartments/Mr. Fayaz/thumb.png", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: Mr. Fayaz </p>
                        <Link to='/Album' state={{ from: "fayaz", client: "Mr. Fayaz" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>


                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('appartments/Mr. Nijamuddin/thumb.png", backgroundPosition: 'right', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: Mr. Nijamuddin BTP </p>
                        <Link to='/Album' state={{ from: "nijam", client: "Mr. Nijamuddin" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('appartments/Mr. Hero/thumb.png", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: Mr. Hero </p>
                        <Link to='/Album' state={{ from: "hero", client: "Mr. Hero" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>


                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96  child' style={{ backgroundImage: "url('appartments/OC MIZAN/thumb.png')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: OC Mizan</p>
                        <Link to='/Album' state={{ from: "mizan", client: "OC Mizan" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>

               </div>




               <div className='projectHead py-14 w-11/12 h-full float-right' style={{ backgroundImage: "url('bg/restaurants.webp')", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                  {/* title */}
                  <div class="absolute text-studioDark left-5 top-8 text-5xl font-bauhaus">Restaurants</div>
               </div>




               <div className=' w-11/12 mx-auto pt-5 pb-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center'>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96  child' style={{ backgroundImage: "url('restaurants/chattaMetro/thumb.jpg')", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: Chatto Metro </p>
                        <Link to='/Album' state={{ from: "chattametro", client: "Chatta Metro" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('restaurants/saadMoosa/thumb.png", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: Saad Moosa Restaurant </p>
                        <Link to='/Album' state={{ from: "saadmoosa", client: "Saad Moosa Restaurant" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>


                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('restaurants/magpie/thumb.jpg", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Client: Magpie </p>
                        <Link to='/Album' state={{ from: "magpie", client: "Magpie" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>
               </div>


               <div className='projectHead py-14 w-11/12 h-full float-left' style={{ backgroundImage: "url('j to d/jtod (10).jpg')", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                  {/* title */}
                  <div class="absolute text-studioDark right-5 top-8 text-5xl font-bauhaus">Paintings</div>
               </div>



               <div className='w-11/12 mx-auto pt-5 pb-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center'>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96  child' style={{ backgroundImage: "url('j to d/jtod (9).jpg')", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Album: Journey to Dark </p>
                        <Link to='/Album' state={{ from: "jtod", client: "Journey to Dark" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('landscape/landscape (18).png", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Album: Landscape </p>
                        <Link to='/Album' state={{ from: "landscape", client: "Landscape" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>


                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('portrait/portrait (1).png", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Album: Portrait </p>
                        <Link to='/Album' state={{ from: "portrait", client: "Portrait" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96  child' style={{ backgroundImage: "url('close up/close-up-2.png')", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Album: Close Up </p>
                        <Link to='/Album' state={{ from: "closeup", client: "Close Up" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>

                  <div className='parent relative w-full border-8 border-studioClay overflow-hidden'>
                     <div className='bg-studioDark w-full h-96 child' style={{ backgroundImage: "url('seascape/seascape (14).png", backgroundPosition: 'bottom', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                     </div>
                     <div className='titleTile absolute py-2 px-2 -bottom-20 left-0 w-full font-playfair bg-studioClay'>
                        <p className='text-gray-200 font-bold text-sm italic'> Album: seascape </p>
                        <Link to='/Album' state={{ from: "seascape", client: "Seascape" }} className='text-gray-200 font-bold'>View Album <HiArrowNarrowRight className='inline-block' /></Link>
                     </div>
                  </div>
               </div>


               <div className='projectHead py-14 w-11/12 h-full float-right clear-both' style={{ backgroundImage: "url('tkottta/tera-2.png')", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                  {/* title */}
                  <div class="absolute text-studioDark left-5 top-8 text-5xl font-bauhaus">Teracottas</div>
               </div>




               <div className='w-11/12 mx-auto pt-5 pb-20 clear-both'>
                  <PhotoAlbum
                     photos={photos}
                     layout="masonry"
                     padding={2}
                     spacing={5}
                     targetRowWidth={150}
                     onClick={({ index }) => setIndex(index)}
                  />

                  <Lightbox
                     slides={slides}
                     open={index >= 0}
                     index={index}
                     close={() => setIndex(-1)}
                     // enable optional lightbox plugins
                     plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                  />
               </div>

            </div>
         </Disclosure >
      </>
   )
}
