import React from "react";
import { FaHome, FaListAlt, FaPlusSquare, FaChartLine } from "react-icons/fa";
let navOpts = [
   {
      tab: "Home",
      href: "Home",
      current: true,
   },

   {
      tab: "Services",
      href: "Services",
      current: false,
   },
   {
      tab: "Portfolio",
      href: "Portfolio",
      current: false,
   },
   {
      tab: "Team",
      href: "Team",
      current: false,
   },
   {
      tab: "Contact",
      href: "Contact",
      current: false,
   }
]

export function Navmenu() {
   return navOpts;
}