
import { Navbar, Button } from 'flowbite-react'
import { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom";
import { Navmenu } from "./Navmenu"
let navOpts = Navmenu()
const navigation = [
   { name: 'Home' },
   { name: 'Services', },
   { name: 'Portfolio', },
   { name: 'Team', },
   { name: 'Contact', },
]
function classNames(...classes) {
   return classes.filter(Boolean).join(' ')
}


export default function MainNav() {
   const [isToggled, setToggle] = useState("true");




   const handleClick = () => {
      setToggle(!isToggled);
   };

   const handleToggle = () => {
      setToggle(!isToggled);
   };


   const [padSize, setnavSize] = useState("3rem");
   const [navColor, setnavColor] = useState("transparent");
   const [navShadow, setnavShadow] = useState("none");
   const listenScrollEvent = () => {
      window.scrollY > 10 ? setnavColor("#d6c09B") : setnavColor("transparent");
      window.scrollY > 10 ? setnavSize("1.2rem") : setnavSize("3rem");
      window.scrollY > 10 ? setnavShadow("0px 2px 5px black") : setnavShadow("none");
   };
   useEffect(() => {
      window.addEventListener("scroll", listenScrollEvent);
      return () => {
         window.removeEventListener("scroll", listenScrollEvent);
      };
   }, []);
   return (
      <nav className='grid grid-cols-1 content-center items-center h-fit fixed w-full z-50 lg:bg-transparent font-SourceSans py-10' style={{
         backgroundColor: navColor,
         paddingTop: padSize,
         paddingBottom: padSize,
         boxShadow: navShadow,
         transition: "all .5s"
      }}>
         <div className='content-center items-center lg:mx-auto'>
            <img
               src='logo.png'
               className='absolute lg:left-3 lg:-translate-y-1/2 lg:translate-x-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-10 block mx-auto'
            />
            <ul className='font-playfair hidden lg:inline-block content-center items-center' >
               {navOpts.map((tablist, index) => (
                  <li key={index} className="inline-block">
                     <NavLink to={tablist.href} className={({ isActive }) =>
                        isActive ? "text-md line-through font-playfair font-bold px-2 text-studioClay" : "font-bold text-md font-playfair px-2 text-studioGrundge hover:text-studioDark"
                     }>
                        {tablist.tab}
                     </NavLink>
                  </li>
               ))}
            </ul>
            <div className='absolute right-3 top-1/2 -translate-y-1/2'>
               <a href='tel:012345678' className='text-studioCream px-3 py-1 rounded-sm clear-both align-middle bg-studioGrundge order-last font-SourceSans'>Call Us</a>
            </div>

            <div className='block lg:hidden mx-10'>
               <button className={(isToggled ? 'navicon--toggle ' : 'navicon')} onClick={handleToggle}>
                  <div className='navicon__holder'>
                     <div className='navicon__line'></div>
                     <div className='navicon__line'></div>
                     <div className='navicon__line'></div>
                  </div>
               </button>
            </div>

         </div >
         <div className={classNames(isToggled ? 'block' : 'hidden',
            "fixed z-100 top-0 w-full bg-studioGrundge min-h-screen lg:hidden")} >
            <ul className='font-playfair block lg:hidden text-4xl'>
               {navOpts.map((tablist, index) => (
                  <li key={index} className="block">
                     <NavLink to={tablist.tab} className={({ isActive }) =>
                        isActive ? "font-thin line-through font-playfair px-2 text-playfair text-studioWall" : "font-playfair px-2 text-studioClay hover:text-studioWall"
                     } onClick={handleClick}>
                        {tablist.tab}
                     </NavLink>
                  </li>
               ))}
            </ul>
         </div>

      </nav >
   )
}
