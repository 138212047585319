import React, { Component } from "react";
import Slider from "react-slick";
import "../index.css"


const baseUrl = "Mr. AJIJ/3d"
export default class CenterMode extends Component {
   render() {
      const settings = {
         customPaging: function (i) {
            return (
               <a>
                  <img src={`${baseUrl}/abstract (${i + 1}).jpg`} />
               </a>
            );
         },
         dots: true,
         arrows: false,
         autoplay: true,
         dotsClass: "slick-dots slick-thumb slick-custom",
         infinite: true,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1
      };
      return (
         <div className="grid lg:grid-cols-3 mb-20">
            <div className="relative bg-studioGrundge text-studioDark">
               <h2 className="absolute right-0 -rotate-90 top-1/2 -translate-y-1/2 text-5xl font-bauhaus ">3D plan</h2>
            </div>
            <div className="col-span-2">
               <Slider {...settings}>
                  <div>
                     <img src={baseUrl + "/abstract (1).jpg"} className="mx-auto" />
                  </div>
                  <div>
                     <img src={baseUrl + "/abstract (2).jpg"} className="mx-auto" />
                  </div>
                  <div>
                     <img src={baseUrl + "/abstract (3).jpg"} className="mx-auto" />
                  </div>
                  <div>
                     <img src={baseUrl + "/abstract (4).jpg"} className="mx-auto" />
                  </div>
                  <div>
                     <img src={baseUrl + "/abstract (5).jpg"} className="mx-auto" />
                  </div>
                  <div>
                     <img src={baseUrl + "/abstract (6).jpg"} className="mx-auto" />
                  </div>

               </Slider>
            </div>

         </div>
      );
   }
}