
import { Parallax } from 'react-parallax';
import PlanSlider from '../components/PlanSlider';
import Liveslider from '../components/Liveslider';
import Footer from './Footer';

const insideStyles = {
   position: "absolute",
   top: "90%",
   left: "0",
   transform: "translate(5%,-50%)"
};
function IndividualProjects() {
   return (

      <div className="w-full bg-studioCream relative">
         <Parallax
            bgImage="17.jpg"
            blur={{ min: -5, max: 5 }}
            bgImageStyle={{ backgroundPosition: "bottom", objectFit: "cover" }}
            strength={-300}>
            <div style={{ height: "70vh" }} className="relative">
               <div style={insideStyles} className=" font-bauhaus text-studioGrundge text-5xl text-stone-400">Apartment: Interior</div>
            </div>
         </Parallax>
         <div className=' bg-studioWall text-studioGrundge'>
            <h1 className=' font-bauhaus text-5xl'>Our Workflow</h1>
         </div>

         <div className='grid lg:grid-cols-2'>
            <h3 className='text-lg'>2D Plan</h3>
            <img src='Mr. AJIJ/2d/2d blue print.jpg' />
            <img src='Mr. AJIJ/2d/layout 1.jpg' />
         </div>
         <PlanSlider />
         <br />
         <Liveslider />
         <br />
         <Footer />
      </div >
   )
}
export default IndividualProjects;