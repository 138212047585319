import '../index.css';
import Herobackdrop from '../components/Herobackdrop';
import Projects from '../components/Projects';
import Footer from '../components/Footer';
import MyComponent from '../components/MyComponent';
import About from '../components/About';
import Responsive from '../components/Feedback';
import ServicesShort from './ServicesShort';
function App() {
   return (
      <div>
         <Herobackdrop />
         <About />
         <ServicesShort />
         <Projects />
         <Responsive />
         <Footer />
      </div>
   );
}

export default App;
